
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";

import AgeGenderGroup from "../../models/sporteventCalculation/AgeGenderGroup";
import AgeGenderGroupService from "../../services/sporteventCalculation/AgeGenderGroupService";

@Component
export default class AgeGenderGroupComponent extends Vue {
  public ageGenderGroup: AgeGenderGroup = new AgeGenderGroup();
  public genderOptions = [
    { value: "M", text: i18n.tc("societymgmt.male") },
    { value: "F", text: i18n.tc("societymgmt.female") },
    { value: "B", text: i18n.tc("societymgmt.both") },
  ];

  mounted(): void {
    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      AgeGenderGroupService.get(this.$route.params.id).then((item) => {
        this.ageGenderGroup = item;
      });
    }
  }

  save(): void {
    AgeGenderGroupService.save(this.ageGenderGroup).then(
      (item: AgeGenderGroup) => {
        item = new AgeGenderGroup(item); // otherwise you cannot use the functions
        ToastService.Success(i18n.tc("labels.saved"), item.getName());
        if (item) {
          this.ageGenderGroup = item;
          this.handleBack();
        }
      }
    );
  }

  handleBack(): void {
    this.$router.back();
  }
}
